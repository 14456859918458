<template>
  <div>
    <flickity ref="flickity" :options="flickityOptions" v-if="isReady && hasData" :class="`carousel--${ size } mx-auto`">
        <div v-for="image in images" :key="'adspace-' + code + '-' + image.id" class="carousel__slides__cell" :class="`slide--${ size }`">
          <a class="mx-auto my-4 overflow-hidden ad-space"
            :class="`ad-space--${ size }`"
            :href="image.linkToUrl"
            rel="noopener"
            target="_blank"
            :title="image.linkTitleText"
          >
            <img
              class="position-absolute"
              loading="lazy"
              :src="image.imgSrc"
              
              :alt="image.imageAltText"
            />
          </a>
        </div>
      </flickity>
  </div>
</template>

<script>
import { AdvertisementGroupService } from "@/services/";
import Flickity from 'vue-flickity';
export default {
  name: "app-adspace",
  components:{Flickity},
  props: {
    code: { type: String, require: true },
    count: { type: Number, default: 1 },
    size: {
      default: "4x3",
      validator: function(value) {
        return ["wide-short", "4x3"].indexOf(value) !== -1;
      }
    },
    adImages: {type: Array}
  },
  data: function() {
    return {
      hasData: false,
      isReady: false,
      images: [],
      imageUrl: null,
      imageAltText: null,
      linkUrl: null,
      linkTitleText: null,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        wrapAround: true,
        autoPlay: 60000,
        pageDots: false,
        setGallerySize: true,
        fullScreen: true
      }
    };
  },
  mounted() {
    if(this.adImages ==null||this.adImages == undefined || this.adImages.length <=0){
      AdvertisementGroupService.getAllAds(this.code).then(({ data }) => {
        this.images = data.map(({ id, imgAltText, imgSrc, linkTitleText, linkToUrl }) => ({ id, imgAltText, imgSrc, linkTitleText, linkToUrl }));
        this.hasData = this.isReady = (this.images != null && this.images != undefined && this.images.length >0);
      });
    }else{
      this.images = this.adImages;
      this.hasData = this.isReady = (this.images != null && this.images != undefined && this.images.length >0);
    }
    
  }
};
</script>
